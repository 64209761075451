"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthClientEdit = exports.OauthClientEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const oauth_context_form_1 = require("./oauth-context-form");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const apis_2 = require("../tenant/apis");
const Constants = require("./constants");
const entity_1 = require("../tenant/entity");
class OauthClientEdit extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.oauthClientPK = Number((0, lib_es2015_2.getAttributeOrThrowError)(this, 'oauth-client-pk'));
        this.tenantApis = new apis_2.default();
        this.oauthContextForms = {};
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const entity = yield this.apis.getByPK({ pk: this.oauthClientPK }, ['tenant']);
            if (entity === null) {
                alert('fail to load tenant data.');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">pk</div>
      <div>${this.oauthClientPK}</div>
      
      <div class="edit-form-field-name">Tenant Id</div>
      <div>${entity.tenant.id} (${entity.tenant_pk})</div>
      
      <div class="edit-form-field-name">Oauth Client Id</div>
      <div>${entity.id}</div>
      
      <div class="edit-form-field-name">Password</div>
      <div><input type="password" class="password" /></div>
      
      <div class="edit-form-field-name">Confirm Password</div>
      <div><input type="password" class="password-confirm" /></div>
      
      <div class="edit-form-field-name">Member Extra Keys</div>
      <div><input-list class="member-extra-key"></input-list></div>
    </div>
    
    <div class="oauth-context-form-container"></div>
    <div class="oauth-context-btn-container"><button class="append-oauth-context-btn">Append Oauth Resource Server</button></div>
    
    <div class="edit-form-footer"><button class="submit-btn">Submit</button></div>`;
            this.passwordInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.password');
            this.memberExtraKeyInputList = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input-list.member-extra-key');
            this.memberExtraKeyInputList.value = entity.member_extra_keys.map(key => ({ value: key, extra: '' }));
            const oauthContextFormContainer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.oauth-context-form-container');
            Object.entries(entity.oauth_contexts).forEach(([resourceServer, oauthContext]) => {
                const oauthCtxForm = this.oauthContextForms[resourceServer];
                if (oauthCtxForm !== undefined && oauthCtxForm !== null) {
                    alert(`The resource server of name ${resourceServer} already exists`);
                    return;
                }
                if (oauthContext === null) {
                    alert(`the oauth context of ${resourceServer} is null!`);
                    return;
                }
                const form = new oauth_context_form_1.OauthContextForm();
                this.oauthContextForms[resourceServer] = form;
                form.setAttribute('resource-server', resourceServer);
                form.addEventListener('on-delete-btn-click', () => {
                    const ans = confirm(`Do you really want to delete oauth context pk: ${oauthContext.pk} of oauth client: ${oauthContext.oauth_client_pk} of resource server ${oauthContext.resource_server}?`);
                    if (!ans) {
                        return;
                    }
                    void (() => __awaiter(this, void 0, void 0, function* () {
                        const resp = yield this.apis.deleteOauthContext({ pk: oauthContext.pk });
                        if (resp === null) {
                            return;
                        }
                        alert(`delete oauth context pk: ${oauthContext.pk} of oauth client: ${oauthContext.oauth_client_pk} of resource server ${oauthContext.resource_server}, successfully!`);
                        (0, lib_es2015_2.getParentElementOrThrowError)(form).removeChild(form);
                        delete this.oauthContextForms[resourceServer];
                    }))();
                });
                form.value = oauthContext;
                oauthContextFormContainer.appendChild(form);
            });
            (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.append-oauth-context-btn').addEventListener('click', () => {
                const resourceServer = prompt('Put oauth resource server name, e.g. google, facebook, kakao, naver and etc...');
                if (resourceServer === null || resourceServer === '') {
                    return;
                }
                const oauthCtxForm = this.oauthContextForms[resourceServer];
                if (oauthCtxForm !== undefined && oauthCtxForm !== null) {
                    alert(`The resource server of name ${resourceServer} already exists`);
                    return;
                }
                const form = new oauth_context_form_1.OauthContextForm();
                this.oauthContextForms[resourceServer] = form;
                form.setAttribute('resource-server', resourceServer);
                form.addEventListener('on-delete-btn-click', () => {
                    (0, lib_es2015_2.getParentElementOrThrowError)(form).removeChild(form);
                    delete this.oauthContextForms[resourceServer];
                });
                form.value = {
                    pk: 0,
                    oauth_client_pk: this.oauthClientPK,
                    resource_server: resourceServer,
                    client_id: '',
                    client_secret: '',
                    auth_style: 1,
                    token_url: '',
                    auth_url: '',
                    redirect_url: '',
                    scopes: [],
                };
                oauthContextFormContainer.appendChild(form);
            });
            const passwordConfirmInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.password-confirm');
            (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', () => {
                if (this.passwordInput.value === '') {
                    alert(`put ${Constants.subjectName} password`);
                    return;
                }
                if (this.passwordInput.value !== passwordConfirmInput.value) {
                    alert(`${Constants.subjectName} passwords are different`);
                    return;
                }
                const ans = confirm(`Are you sure to update ${Constants.subjectName} info?`);
                if (!ans)
                    return;
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.update({
                        pk: entity.pk,
                        tenant_pk: entity.tenant_pk,
                        id: entity.id,
                        password: this.passwordInput.value,
                        member_extra_keys: this.memberExtraKeyInputList.value.map(v => v.value),
                        oauth_contexts: Object.fromEntries(Object.entries(this.oauthContextForms).map(([resourceServer, form]) => [resourceServer, form.value])),
                        tenant: (0, entity_1.newEntity)(),
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert(`updated ${Constants.subjectName}, successfully!`);
                    passwordConfirmInput.value = '';
                    this.router.push(`/web/${Constants.routerPathGroup}/list?page_no=1&page_size=10&query=&tenant_pk=`);
                }))();
            });
        });
    }
}
exports.OauthClientEdit = OauthClientEdit;
const defineOauthClientEdit = (name) => {
    (0, oauth_context_form_1.defineOauthContextForm)('oauth-context-form');
    (0, lib_es2015_1.defineIfUndefined)(name, OauthClientEdit);
};
exports.defineOauthClientEdit = defineOauthClientEdit;
