"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMemberGroupEdit = exports.MemberGroupEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/complex-inputs/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const Constants = require("./constants");
const apis_2 = require("../oauth-client/apis");
const OauthClient = require("../oauth-client/entity");
class MemberGroupEdit extends HTMLElement {
    constructor() {
        super();
        this.memberGroupPK = Number((0, lib_es2015_3.getAttributeOrThrowError)(this, 'member-group-pk'));
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.oauthClientApis = new apis_2.default();
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const entity = yield this.apis.getByPK({ pk: this.memberGroupPK }, ['oauth_client']);
            if (entity === null) {
                alert('fail to load tenant data.');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">pk</div>
      <div>${entity.pk}</div>
      
      <div class="edit-form-field-name">Oauth Client Id</div>
      <div>${entity.oauth_client.id} (${entity.oauth_client_pk})</div>
      
      <div class="edit-form-field-name">Name</div>
      <div><input class="name" /></div>
      
      <div class="edit-form-footer"><button class="submit-btn">Submit</button></div>
    </div>`;
            this.nameInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.name');
            this.nameInput.value = entity.name;
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', () => {
                if (this.nameInput.value === '') {
                    alert(`put ${Constants.subjectName} name`);
                    return;
                }
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const ans = confirm(`Are you sure to update ${Constants.subjectName} info?`);
                    if (!ans)
                        return;
                    const apiResp = yield this.apis.update({
                        pk: entity.pk,
                        oauth_client_pk: entity.oauth_client_pk,
                        name: this.nameInput.value,
                        oauth_client: OauthClient.newEntity(),
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert(`updated ${Constants.subjectName}, successfully!`);
                    this.router.push(`/web/${Constants.routerPathGroup}/list?page_no=1&page_size=10&query=&oauth_client_pk=`);
                }))();
            });
        });
    }
}
exports.MemberGroupEdit = MemberGroupEdit;
const defineMemberGroupEdit = (name) => {
    (0, lib_es2015_2.defineSearchSelector)('search-selector');
    (0, lib_es2015_1.defineIfUndefined)(name, MemberGroupEdit);
};
exports.defineMemberGroupEdit = defineMemberGroupEdit;
