"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.codeProcessor = void 0;
const constants_1 = require("@hodol/webapp-common/lib-es2015/common/constants");
const BAD_REQUEST = 'BAD_REQUEST';
const INTERNAL_ERROR = 'INTERNAL_ERROR';
const codeProcessor = (apiResponse) => {
    if (apiResponse.code === BAD_REQUEST || apiResponse.code === INTERNAL_ERROR || apiResponse.code === constants_1.UsualResponseCode.PERMISSION_DENIED) {
        alert(`[${apiResponse.code}] ${apiResponse.error}`);
        return { isContinue: false, body: null };
    }
    return { isContinue: true, body: null };
};
exports.codeProcessor = codeProcessor;
