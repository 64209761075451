"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMetadataEdit = exports.MetadataEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const Constants = require("./constants");
class MetadataEdit extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const memberServerName = yield this.apis.getMemberServerName();
            if (memberServerName === null) {
                alert('fail to load member server name, the server may not installed');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div>Member Server Name</div>
      <div><input class="member-server-name" /></div>
      
      <div>Admin ID</div>
      <div><input class="admin-id" /></div>
      
      <div class="edit-form-footer"><button class="submit-btn">Submit</button></div>
    </div>`;
            this.memberServerNameInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.member-server-name');
            this.memberServerNameInput.value = memberServerName;
            this.adminIdInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.admin-id');
            (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', () => {
                const ans = confirm(`Are you sure to update ${Constants.subjectName} info?`);
                if (!ans)
                    return;
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.update({
                        member_server_name: this.memberServerNameInput.value,
                        admin_id: this.adminIdInput.value,
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert(`updated ${Constants.subjectName}, successfully!`);
                    this.router.push(`/web`);
                }))();
            });
        });
    }
}
exports.MetadataEdit = MetadataEdit;
const defineMetadataEdit = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, MetadataEdit);
};
exports.defineMetadataEdit = defineMetadataEdit;
