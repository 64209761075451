"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newEntity = newEntity;
function newEntity() {
    return {
        pk: 0,
        password: '',
        id: '',
    };
}
