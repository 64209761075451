"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMemberRegister = exports.MemberRegister = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/complex-inputs/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const Constants = require("./constants");
const apis_2 = require("../oauth-client/apis");
const apis_3 = require("../resource-owner/apis");
const apis_4 = require("../member-group/apis");
const OauthClient = require("../oauth-client/entity");
const ResourceOwner = require("../resource-owner/entity");
class MemberRegister extends HTMLElement {
    constructor() {
        super(...arguments);
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.oauthClientApis = new apis_2.default();
        this.resourceOwnerApis = new apis_3.default();
        this.memberGroupApis = new apis_4.default();
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.style.setProperty('display', 'block');
            this.innerHTML = `
    <div class="register-form">
      <div class="register-form-field-name">Oauth Client Id</div>
      <div class="register-form-field-value"><search-selector class="oauth-client-pk"></search-selector></div>
      
      <div class="register-form-field-name">Resource Owner Global Identifier</div>
      <div class="register-form-field-value"><search-selector class="resource-owner-pk"></search-selector></div>
      
      <div class="register-form-field-name">Member Group Names</div>
      <div class="register-form-field-value">
        <div><search-selector-list class="member-group-pks"></search-selector-list></div>
      </div>
      
      <div class="register-form-field-name">Nick</div>
      <div class="register-form-field-value"><input class="nick" /></div>
      
      <div class="register-form-field-name">Pre-title</div>
      <div class="register-form-field-value"><input class="pre-title" /></div>
      
      <div class="register-form-field-name">Post-title</div>
      <div class="register-form-field-value"><input class="post-title" /></div>
      
      <div class="register-form-field-name">Extra</div>
      <div class="register-form-field-value">
        <div><input-map class="extra" max-key-textarea-width="200" max-value-textarea-width="500"></input-map></div>
      </div>

      <div class="register-form-field-name">Oauth Access Token</div>
      <div class="register-form-field-value"><textarea class="oauth-info-access-token" disabled></textarea></div>
      
      <div class="register-form-field-name">Oauth Token Type</div>
      <div class="register-form-field-value"><input class="oauth-info-token-type" disabled /></div>
      
      <div class="register-form-field-name">Oauth Expired At</div>
      <div class="register-form-field-value"><input type="datetime-local" class="oauth-info-expire-at" disabled /></div>
      
      <div class="register-form-field-name">Oauth Refresh Token</div>
      <div class="register-form-field-value"><input class="oauth-info-refresh-token" disabled /></div>
      
      <div class="register-form-field-name">Oauth Resource Server</div>
      <div class="register-form-field-value"><input class="oauth-info-resource-server" disabled /></div>
            
      <div class="register-form-footer"><button class="submit-btn">Submit</button></div>
    </div>`;
            this.oauthClientPKSelector = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'search-selector.oauth-client-pk');
            this.oauthClientPKSelector.init({ value: '', displayText: '', description: '' }, this.oauthClientApis.listForSearchSelector);
            this.resourceOwnerPKSelector = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'search-selector.resource-owner-pk');
            this.resourceOwnerPKSelector.init({ value: '', displayText: '', description: '' }, this.resourceOwnerApis.listForSearchSelector);
            this.memberGroupPKSelectorList = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'search-selector-list.member-group-pks');
            this.memberGroupPKSelectorList.init([], this.memberGroupApis.getListApiForMemberGroupSearchSelectorByOauthClientPK(''));
            this.oauthClientPKSelector.addEventListener('change', () => {
                this.memberGroupPKSelectorList.init([], this.memberGroupApis.getListApiForMemberGroupSearchSelectorByOauthClientPK(this.oauthClientPKSelector.value));
                if (this.memberGroupPKSelectorList.value.length > 0) {
                    alert('Remove member groups which are depend on oauth client');
                    this.memberGroupPKSelectorList.removeAll();
                }
            });
            this.nickInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.nick');
            this.preTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.pre-title');
            this.postTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.post-title');
            this.extraInputMap = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input-map.extra');
            this.oauthClientPKSelector.addEventListener('change', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const oauthClient = yield this.oauthClientApis.getByPK({ pk: Number(this.oauthClientPKSelector.value) }, []);
                    if (oauthClient === null) {
                        return;
                    }
                    if (oauthClient.member_extra_keys === undefined)
                        return;
                    oauthClient.member_extra_keys.forEach(extraKey => this.extraInputMap.addItem(extraKey, '', ''));
                }))();
            });
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', () => {
                if (this.oauthClientPKSelector.value === '') {
                    alert(`put ${Constants.subjectName} oauth client pk`);
                    return;
                }
                if (this.resourceOwnerPKSelector.value === '') {
                    alert(`put ${Constants.subjectName}'s resource owner pk.`);
                    return;
                }
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const insertedPKOrNull = yield this.apis.insert({
                        pk: 0,
                        oauth_client_pk: Number(this.oauthClientPKSelector.value),
                        resource_owner_pk: Number(this.resourceOwnerPKSelector.value),
                        nick: this.nickInput.value,
                        pre_title: this.preTitleInput.value,
                        post_title: this.postTitleInput.value,
                        oauth_info: {
                            access_token: '',
                            token_type: '',
                            expires_at: null,
                            refresh_token: '',
                            resource_server: '',
                        },
                        member_group_pks: this.memberGroupPKSelectorList.value.map(pk => Number(pk)),
                        extra: Object.fromEntries(this.extraInputMap.value.map(entry => [entry.key, entry.value])),
                        is_initialized: false,
                        oauth_client: OauthClient.newEntity(),
                        resource_owner: ResourceOwner.newEntity(),
                        member_groups: [],
                    });
                    if (insertedPKOrNull === null) {
                        return;
                    }
                    alert('Created Member Successfully!');
                    this.router.push(`/web/${Constants.routerPathGroup}/list?page_no=1&page_size=10&query=&oauth_client_pk=&resource_owner_pk=&member_group_pks=`);
                }))();
            });
        });
    }
}
exports.MemberRegister = MemberRegister;
const defineMemberRegister = (name) => {
    (0, lib_es2015_2.defineSearchSelector)('search-selector');
    (0, lib_es2015_2.defineSearchSelectorList)('search-selector-list');
    (0, lib_es2015_2.defineInputMap)('input-map');
    (0, lib_es2015_1.defineIfUndefined)(name, MemberRegister);
};
exports.defineMemberRegister = defineMemberRegister;
