"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newEntity = newEntity;
const entity_1 = require("../tenant/entity");
function newEntity() {
    return {
        pk: 0,
        tenant_pk: 0,
        id: '',
        password: '',
        member_extra_keys: [],
        oauth_contexts: {},
        tenant: (0, entity_1.newEntity)(),
    };
}
