"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMemberEdit = exports.MemberEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/complex-inputs/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const apis_2 = require("../oauth-client/apis");
const apis_3 = require("../resource-owner/apis");
const apis_4 = require("../member-group/apis");
const Constants = require("./constants");
const utils_1 = require("../resource-owner/utils");
const OauthClient = require("../oauth-client/entity");
const ResourceOwner = require("../resource-owner/entity");
class MemberEdit extends HTMLElement {
    constructor() {
        super();
        this.memberPK = Number((0, lib_es2015_3.getAttributeOrThrowError)(this, 'member-pk'));
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.oauthClientApis = new apis_2.default();
        this.resourceOwnerApis = new apis_3.default();
        this.memberGroupApis = new apis_4.default();
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const entity = yield this.apis.getByPK({ pk: this.memberPK }, ['oauth_client', 'resource_owner', 'member_groups']);
            if (entity === null) {
                alert('fail to load tenant data.');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">pk</div>
      <div class="edit-form-field-value">${this.memberPK}</div>
      
      <div class="edit-form-field-name">Oauth Client Id</div>
      <div class="edit-form-field-value">${entity.oauth_client.id} (${entity.oauth_client_pk})</div>
      
      <div class="edit-form-field-name">Resource Owner Global Identifier</div>
      <div class="edit-form-field-value">${(0, utils_1.formatGlobalIdentifier)(entity.resource_owner.global_identifier)}</div>
      
      <div class="edit-form-field-name">Member Group Names</div>
      <div class="edit-form-field-value">
        <div><search-selector-list class="member-group-pks"></search-selector-list></div>
      </div>
      
      <div class="edit-form-field-name">Nick</div>
      <div class="edit-form-field-value"><input class="nick" /></div>
      
      <div class="edit-form-field-name">Pre-title</div>
      <div class="edit-form-field-value"><input class="pre-title" /></div>
      
      <div class="edit-form-field-name">Post-title</div>
      <div class="edit-form-field-value"><input class="post-title" /></div>
      
      <div class="edit-form-field-name">Extra</div>
      <div class="edit-form-field-value">
        <div><input-map class="extra" max-key-textarea-width="200" max-value-textarea-width="500"></input-map></div>
      </div>
      
      <div class="edit-form-field-name">Oauth Access Token</div>
      <div class="edit-form-field-value"><textarea class="oauth-info-access-token" disabled></textarea></div>
      
      <div class="edit-form-field-name">Oauth Token Type</div>
      <div class="edit-form-field-value"><input class="oauth-info-token-type" disabled /></div>
      
      <div class="edit-form-field-name">Oauth Expired At</div>
      <div class="edit-form-field-value"><input type="datetime-local" class="oauth-info-expire-at" step="1" disabled /></div>
      
      <div class="edit-form-field-name">Oauth Refresh Token</div>
      <div class="edit-form-field-value"><input class="oauth-info-refresh-token" disabled /></div>
      
      <div class="edit-form-field-name">Oauth Resource Server</div>
      <div class="edit-form-field-value"><input class="oauth-info-resource-server" disabled /></div>
      
      <div class="edit-form-footer"><button class="submit-btn">Submit</button></div>
    </div>`;
            this.memberGroupPKSelectorList = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'search-selector-list.member-group-pks');
            this.memberGroupPKSelectorList.init(entity.member_group_pks.map(memberGroupPK => {
                const filtered = entity.member_groups.filter(mg => mg.pk === memberGroupPK);
                if (filtered.length !== 1) {
                    throw Error(`member group of pk, ${memberGroupPK} is not unique`);
                }
                return {
                    value: `${memberGroupPK}`,
                    displayText: `${filtered[0].name}`,
                    description: '',
                };
            }), this.memberGroupApis.getListApiForMemberGroupSearchSelectorByOauthClientPK(`${entity.oauth_client_pk}`));
            this.nickInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.nick');
            this.nickInput.value = entity.nick;
            this.preTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.pre-title');
            this.preTitleInput.value = entity.pre_title;
            this.postTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.post-title');
            this.postTitleInput.value = entity.post_title;
            this.extraInputMap = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input-map.extra');
            this.extraInputMap.value = entity.extra !== undefined ? Object.keys(entity.extra).map(key => ({ key, value: entity.extra[key], extra: '' })) : [];
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', () => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const ans = confirm(`Are you sure to update ${Constants.subjectName} info?`);
                    if (!ans)
                        return;
                    const apiResp = yield this.apis.update({
                        pk: this.memberPK,
                        oauth_client_pk: entity.oauth_client_pk,
                        resource_owner_pk: entity.resource_owner_pk,
                        nick: this.nickInput.value,
                        pre_title: this.preTitleInput.value,
                        post_title: this.postTitleInput.value,
                        oauth_info: {
                            access_token: '',
                            token_type: '',
                            expires_at: null,
                            refresh_token: '',
                            resource_server: '',
                        },
                        member_group_pks: this.memberGroupPKSelectorList.value.map(pk => Number(pk)),
                        extra: Object.fromEntries(this.extraInputMap.value.map(entry => [entry.key, entry.value])),
                        is_initialized: false,
                        oauth_client: OauthClient.newEntity(),
                        resource_owner: ResourceOwner.newEntity(),
                        member_groups: [],
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert('Updated Member Successfully!');
                    this.router.push(`/web/${Constants.routerPathGroup}/list?page_no=1&page_size=10&query=&oauth_client_pk=&resource_owner_pk=&member_group_pks=`);
                }))();
            });
        });
    }
}
exports.MemberEdit = MemberEdit;
const defineMemberEdit = (name) => {
    (0, lib_es2015_2.defineSearchSelector)('search-selector');
    (0, lib_es2015_2.defineSearchSelectorList)('search-selector-list');
    (0, lib_es2015_2.defineInputMap)('input-map');
    (0, lib_es2015_1.defineIfUndefined)(name, MemberEdit);
};
exports.defineMemberEdit = defineMemberEdit;
