"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineTenantEdit = exports.TenantEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const Constants = require("./constants");
const constants_1 = require("./constants");
class TenantEdit extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_2.useRouter)();
        this.tenantPK = Number((0, lib_es2015_1.getAttributeOrThrowError)(this, constants_1.ATTR_TENANT_PK));
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const entity = yield this.apis.getByPK({ pk: this.tenantPK });
            if (entity === null) {
                alert('fail to load tenant data.');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">pk</div>
      <div>${this.tenantPK}</div>
      <div class="edit-form-field-name">id</div>
      <div>${entity.id}</div>
      <div class="edit-form-field-name">password</div>
      <div><input type="password" class="password" /></div>
      <div class="edit-form-field-name">password confirm</div>
      <div><input type="password" class="password-confirm"/></div>
      <div class="edit-form-footer">
        <button class="submit-btn">Submit</button>
      </div>
    </div>
    `;
            const passwordInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.password');
            const passwordConfirmInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.password-confirm');
            (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', () => {
                if (passwordInput.value !== '' || passwordConfirmInput.value !== '') {
                    if (passwordInput.value !== passwordConfirmInput.value) {
                        alert(`${Constants.subjectName} passwords are different`);
                        return;
                    }
                }
                const ans = confirm(`Are you sure to update ${Constants.subjectName} info?`);
                if (!ans)
                    return;
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.update({
                        pk: entity.pk,
                        id: entity.id,
                        password: passwordInput.value,
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert(`updated ${Constants.subjectName}, successfully, please login again!`);
                    passwordConfirmInput.value = '';
                    this.router.push(`/web/${Constants.routerPathGroup}/list?page_no=1&page_size=10&query=&id=`);
                }))();
            });
        });
    }
}
exports.TenantEdit = TenantEdit;
const defineTenantEdit = (name) => (0, lib_es2015_2.defineIfUndefined)(name, TenantEdit);
exports.defineTenantEdit = defineTenantEdit;
