"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineResourceOwnerEdit = exports.ResourceOwnerEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const utils_1 = require("./utils");
class ResourceOwnerEdit extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.resourceOwnerPK = Number((0, lib_es2015_1.getAttributeOrThrowError)(this, 'resource-owner-pk'));
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const entity = yield this.apis.getByPK({ pk: this.resourceOwnerPK });
            if (entity === null) {
                alert('fail to load resource owner data.');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">pk</div>
      <div>${this.resourceOwnerPK}</div>
      <div class="edit-form-field-name">global_identifier</div>
      <div>${(0, utils_1.formatGlobalIdentifier)(entity.global_identifier)}</div>
      <div class="edit-form-footer">
        <button class="submit-btn" disabled>Submit</button>
      </div>
    </div>
    `;
        });
    }
}
exports.ResourceOwnerEdit = ResourceOwnerEdit;
const defineResourceOwnerEdit = (name) => (0, lib_es2015_2.defineIfUndefined)(name, ResourceOwnerEdit);
exports.defineResourceOwnerEdit = defineResourceOwnerEdit;
