"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthContextForm = exports.OauthContextForm = void 0;
const lib_es2015_1 = require("@hodol/complex-inputs/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
class OauthContextForm extends HTMLElement {
    constructor() {
        super();
        this.resourceServer = '';
        this.style.setProperty('display', 'grid');
        this.style.setProperty('grid-template-columns', 'auto 1fr');
        this.innerHTML = `
    <div><button class="delete-btn">Delete</button></div>
    <div></div>
    
    <div class="oauth-context-field-name">Resource Server Name</div>
    <div class="oauth-context-field-value resource-server-name"></div>
    
    <div class="oauth-context-field-name">OAuth Client Id (for resource server)</div>
    <div class="oauth-context-field-value"><input class="client-id" /></div>
    
    <div class="oauth-context-field-name">OAuth Client Secret</div>
    <div class="oauth-context-field-value"><input class="client-secret" /></div>
    
    <div class="oauth-context-field-name">OAuth Auth URL</div>
    <div class="oauth-context-field-value"><input class="endpoint-auth-url" /></div>
    
    <div class="oauth-context-field-name">OAuth Token URL</div>
    <div class="oauth-context-field-value"><input class="endpoint-token-url" /></div>
    
    <div class="oauth-context-field-name">OAuth Auth Style</div>
    <div class="oauth-context-field-value"><input type="number" class="endpoint-auth-style" /></div>
    
    <div class="oauth-context-field-name">OAuth Redirect URL</div>
    <div class="oauth-context-field-value"><input class="redirect-url" /></div>
    
    <div class="oauth-context-field-name">OAuth Scopes</div>
    <div class="oauth-context-field-value">
      <div>
        <input-list class="scopes" max-textarea-width="500"></input-list>
      </div>
    </div>
    `;
        this.resourceServerNameContainer = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.resource-server-name');
        this.clientIdInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.client-id');
        this.clientSecretInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.client-secret');
        this.endpointAuthUrlInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.endpoint-auth-url');
        this.endpointTokenUrlInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.endpoint-token-url');
        this.endpointAuthStyleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.endpoint-auth-style');
        this.redirectUrlInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.redirect-url');
        this.scopesInputList = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input-list.scopes');
        (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.delete-btn').addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('on-delete-btn-click', {
                detail: this.resourceServer,
            }));
        });
    }
    connectedCallback() {
        this.resourceServer = (0, lib_es2015_3.getAttributeOrThrowError)(this, 'resource-server');
        this.resourceServerNameContainer.innerHTML = this.resourceServer;
    }
    set value(oauthContextEntity) {
        this.pk = oauthContextEntity.pk;
        this.oauth_client_pk = oauthContextEntity.oauth_client_pk;
        this.resourceServer = oauthContextEntity.resource_server;
        this.clientIdInput.value = oauthContextEntity.client_id;
        this.clientSecretInput.value = oauthContextEntity.client_secret;
        this.endpointAuthUrlInput.value = oauthContextEntity.auth_url;
        this.endpointTokenUrlInput.value = oauthContextEntity.token_url;
        this.endpointAuthStyleInput.value = String(oauthContextEntity.auth_style);
        this.redirectUrlInput.value = oauthContextEntity.redirect_url;
        this.scopesInputList.value = oauthContextEntity.scopes.map(scope => ({ value: scope, extra: '' }));
    }
    get value() {
        return {
            pk: this.pk,
            oauth_client_pk: this.oauth_client_pk,
            resource_server: this.resourceServer,
            client_id: this.clientIdInput.value,
            client_secret: this.clientSecretInput.value,
            auth_url: this.endpointAuthUrlInput.value,
            token_url: this.endpointTokenUrlInput.value,
            auth_style: Number(this.endpointAuthStyleInput.value),
            redirect_url: this.redirectUrlInput.value,
            scopes: this.scopesInputList.value.map(v => v.value),
        };
    }
}
exports.OauthContextForm = OauthContextForm;
const defineOauthContextForm = (name) => {
    (0, lib_es2015_1.defineInputList)('input-list');
    (0, lib_es2015_2.defineIfUndefined)(name, OauthContextForm);
};
exports.defineOauthContextForm = defineOauthContextForm;
