"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const Constants = require("./constants");
const lib_es2015_1 = require("@hodol/webapp-common/lib-es2015");
const api_helper_1 = require("../utils/api-helper");
class Apis {
    constructor() {
        this.insert = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(entity),
            }, (api_helper_1.codeProcessor));
        });
        this.list = (request) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}?${(0, lib_es2015_1.buildURLQueries)(request)}`, {
                method: 'GET',
            }, (api_helper_1.codeProcessor));
        });
        this.getByPK = (entity, joinWith) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/pk/${entity.pk}?join_with=${joinWith.length > 0 ? joinWith.join(',') : ''}`, {
                method: 'GET',
            }, (api_helper_1.codeProcessor));
        });
        this.update = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/pk/${entity.pk}`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(entity),
            }, (api_helper_1.codeProcessor));
        });
        this.delete = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/pk/${entity.pk}`, {
                method: 'DELETE',
            }, (api_helper_1.codeProcessor));
        });
        this.deleteOauthContext = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/oauth-context/pk/${entity.pk}`, {
                method: 'DELETE',
            }, (api_helper_1.codeProcessor));
        });
        this.listForSearchSelector = (query) => __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.list({
                query,
                tenant_pk: 0,
                page_size: 0,
                page_no: 0,
                join_with: ['tenant'],
            });
            if (resp === null)
                return [];
            return resp.items.map(i => ({
                value: `${i.pk}`,
                displayText: i.id,
                description: `pk: ${i.pk}, tenant pk: ${i.tenant.pk}`,
            }));
        });
        this.getOauthClientIdByPK = (pk) => __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.getByPK({ pk: pk }, []);
            if (resp === null) {
                return '';
            }
            return resp.id;
        });
        this.getByPKForSearchSelector = (pk) => __awaiter(this, void 0, void 0, function* () {
            return yield this.getOauthClientIdByPK(pk);
        });
    }
}
exports.default = Apis;
